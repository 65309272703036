
import Vue from "vue";
import AgentList from "@/components/organisms/AgentList.vue";
import { User } from "@/lib/models";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { AgentList },
  computed: {
    user() {
      return this.$store.getters["userModule/user"];
    },
  },
  created() {
    if (!this.user.agentStartedAt) {
      this.$router.push({ name: "agentIntro" });
    }

    this.$store.dispatch("novelModule/initialize");
  },
});

interface Props {}

interface Data {}

interface Computed {
  user: User;
}

interface Methods {}
