
import { Novel } from "@/lib/models";
import Vue from "vue";
import AgentNovelListItem from "@/components/molecules/AgentNovelListItem.vue";
import NonAgentNovelListItem from "@/components/molecules/NonAgentNovelListItem.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    AgentNovelListItem,
    NonAgentNovelListItem,
  },
  computed: {
    novels() {
      return this.$store.getters["novelModule/novels"];
    },
    associatedNovels() {
      const { novels } = this;
      return novels.filter((novel) => novel.agentStatus === 1);
    },
    nonAssociatedNovels() {
      const { novels } = this;
      return novels.filter((novel) => novel.agentStatus !== 1);
    },
  },
  data() {
    return {
      isModeAgentStartedNovels: false,
    };
  },
  methods: {
    onClickAssociatedNovels() {
      this.isModeAgentStartedNovels = true;
    },
    onClickNonAssociatedNovels() {
      this.isModeAgentStartedNovels = false;
    },
    onClickCreateNovel() {
      this.$router.push({ name: "novelCreate" });
    },
    onClickAssociate() {
      this.isModeAgentStartedNovels = false;
    },
  },
});

interface Data {
  isModeAgentStartedNovels: boolean;
}

interface Methods {
  onClickAssociatedNovels(): void;
  onClickNonAssociatedNovels(): void;
  onClickCreateNovel(): void;
  onClickAssociate(): void;
}

interface Computed {
  novels: Novel[];
  associatedNovels: Novel[];
  nonAssociatedNovels: Novel[];
}

interface Props {}
