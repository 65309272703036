
import Vue, { PropType } from "vue";
import { NolaAnalytics, NolaItemId } from "@/lib/utils/analytics";
import { format } from "date-fns";
import NolaNovelListItem from "@/components/molecules/NolaNovelListItem.vue";
import { Novel } from "@/lib/models";
import { Dialog } from "@/lib/utils";
import BreakingChangeConfirm, { BreakingChangeConfirmProps } from "@/components/ui/dialogs/BreakingChangeConfirm.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { NolaNovelListItem },
  props: {
    novel: Object as PropType<Novel>,
  },
  data() {
    return {
      nolaAnalytics: new NolaAnalytics(this.$gtm, this.$store),
    };
  },
  computed: {
    title() {
      return this.novel.title;
    },
    updatedAt() {
      const { updatedAtLatestDataInNovel, updatedAt } = this.novel;
      return format(updatedAtLatestDataInNovel || updatedAt!, "YYYY/MM/DD");
    },
    createdAt() {
      const { createdAt } = this.novel;
      return format(createdAt!, "YYYY/MM/DD");
    },
  },
  methods: {
    async onClickUnlinkAssociate() {
      const { novel } = this;
      const { dispatch } = this.$store;

      const confirmDialog = new Dialog(BreakingChangeConfirm);
      const data: BreakingChangeConfirmProps = {
        title: "連携解除前の確認",
        content: "本当にこちらのエージェント連携を解除しますか？",
        change: "解除する",
      };
      const result = await confirmDialog.show(data);

      if (!result) {
        return;
      }

      await dispatch("novelModule/revokeNolaAgentForNovel", { novelId: novel.novelId });
      this.$notify({
        title: "連携を解除しました",
        type: "success",
      });

      this.nolaAnalytics.logButtonEvent(NolaItemId.AgentUnlink, this.novel.novelId);
    },
  },
});

interface Props {
  novel: Novel;
}

interface Data {
  nolaAnalytics: NolaAnalytics;
}

interface Computed {
  title: string;
  updatedAt: string;
  createdAt: string;
}

interface Methods {
  onClickUnlinkAssociate(): void;
}
