
import Vue, { PropType } from "vue";
import ImageView from "@/components/atoms/ImageView.vue";
import { Novel } from "@/lib/models";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ImageView },
  props: {
    novel: Object as PropType<Novel>,
  },
  computed: {
    imageKey() {
      return (novel) => {
        const { novelId, image } = novel;
        if (image && image.startsWith("file:")) {
          return `file:novels/${novelId}/cover.jpg`;
        }
        return image;
      };
    },
  },
});

interface Props {
  novel: Novel;
}

interface Data {}

interface Computed {
  imageKey(novel: Novel): string | undefined;
}

interface Methods {}
